.p-button:focus, .p-button:enabled:focus {
  outline: 0 none;
  box-shadow: inherit;
}

.p-breadcrumb {
  padding: 0;
  background-color: transparent;
  border: none;
}

.p-breadcrumb {
  ul {
    li {
      &.p-breadcrumb-chevron {
        color: #6c757d;
      }

      .p-menuitem-link {
        color: #6c757d;

        &:focus {
          outline: 0 none;
          box-shadow: inherit;
        }
      }

      &:last-of-type {
        .p-menuitem-link {
          cursor: default;
        }
      }
    }
  }
}

.p-component, .p-inputtext {
  font-family: 'Poppins', sans-serif;
}

.p-menuitem {
  .p-menuitem-icon {
    text-align: center;
    width: 24px;
  }

  &.danger {
    a {
      .p-menuitem-icon, .p-menuitem-text {
        color: red;
      }

      &:hover {
        .p-menuitem-icon, .p-menuitem-text {
          color: red;
        }
      }
    }

    .p-menuitem-link:not(.p-disabled) {
      .p-menuitem-icon {
        color: red;
      }
      .p-menuitem-text {
        color: red;
      }

      &:hover {
        .p-menuitem-icon {
          color: red;
        }
        .p-menuitem-text {
          color: red;
        }
      }
    }
  }
}

.p-inputgroup > .p-component, .p-inputgroup > .p-element {
  &.p-inputtextarea {
    border-radius: 6px;
  }
}

::placeholder, .p-placeholder {
  color: #b0b0b0 !important;
  font-family: 'Poppins', sans-serif;
}

.field > small {
  height: 0px;
}

.p-avatar.p-avatar-xxl {
  width: 6rem;
  height: 6rem;
  font-size: 3rem;
  .p-avatar-icon {
    font-size: 3rem;
  }
}

.p-avatar.p-avatar-xxxl {
  width: 8rem;
  height: 8rem;
  font-size: 4rem;
  .p-avatar-icon {
    font-size: 4rem;
  }
}

.p-datatable .p-datatable-thead > tr > th {
  min-width: 120px;
}

.p-datatable-wrapper {
  min-height: 500px;
}

.p-datatable-wrapper {
  .p-datatable-wrapper {
    min-height: initial;
  }
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  margin-bottom: 0.2rem;
}

.p-chips .p-chips-multiple-container .p-chips-token {
  margin-bottom: 0.2rem;
}

p-calendar.p-inputtext-sm {
  .p-button.p-button-icon-only {
    padding: 0;
  }
}

.field {
  margin-bottom: 0.5rem;
}

.ng-invalid.ng-touched {
  border-color: rgb(226, 76, 76);
}

.p-inputwrapper.ng-invalid.ng-touched {
  input {
    border-color: rgb(226, 76, 76);
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.rowexpansion {
  background-color: #eff6ff !important;
  &:hover {
    background-color: initial;
  }
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr.rowexpansion:hover {
  background-color: initial;
  color: initial;
}

.table-empty-message {
  text-align: center !important;
  font-size: 18px;
  line-height: 50px;
  color: #b0b0b0;
}

.p-breadcrumb {
  margin-bottom: 12px;
}

.p-inputtext[readonly], p-dropdown.readonly .p-dropdown {
  opacity: 0.7;
  background-color: #f8f9fa;
}

p-calendar .p-inputtext[readonly] {
  opacity: 1;
  background-color: initial;
}

.p-calendar-disabled {
  .p-inputtext {
    opacity: 0.7;
    background-color: #f8f9fa;
  }
}

.p-inputwrapper {
  .p-inputtext:disabled {
    opacity: 0.7;
    background-color: #f8f9fa;
  }
}

p-columnfilter {
  .p-multiselect .p-multiselect-panel {
    min-width: 250px;
  }
}

.no-data-row {
  td div {
    font-size: 36px;
    color: #b0b0b0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
}

.p-menu {
  width: auto;

  .p-menuitem-link .p-menuitem-text {
    white-space: nowrap;
  }
}

.p-datepicker-buttonbar {
  button {
    &.disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.p-menu .p-menuitem {
  &.danger {
    a > span {
      color: red;
    }
  }
}

.zgis-tab-view {
  .p-tabview-panels {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    padding-top: 3rem;
  }

  .p-tabview-nav {
    background-color: transparent;

    li {
      &:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          background-color: transparent;
        }
      }

      .p-tabview-nav-link {
        background-color: transparent !important;
        width: 160px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
