@import "page";
@import "scss/fonts";
@import "scss/ngx-intl-tel-input-override";

* {
  box-sizing: border-box;
}

html {
  font-size: 14px;
  --cropper-outline-color: rgba(255, 255, 255, 0.5);
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #f0f3f7;
  color: #495057;
}

a {
  cursor: pointer;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-weight: 500;
}

.invisible {
  visibility: hidden;
}

.label {
  &.required {
    &:after {
      content: '*';
      color: red;
    }
  }
}

.height-0 {
  height: 0;
}

.noselect {
  user-select: none;
}

ngx-intl-tel-input {
  .country-dropdown {
    input {
      &:focus-visible {
        outline-color: #b4b4b4;
      }
    }
  }
}

.admin-table-item-menu {
  width: auto;

  .p-menuitem-link {
    .p-menuitem-text {
      white-space: nowrap;
    }
  }
}

table {
  .boolean-icon {
    i {
      font-size: 20px;

      &.pi-check-circle {
        color: #27ba2c;
      }
      &.pi-times-circle {
        color: #ff0800;
      }
    }
  }
}
