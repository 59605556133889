.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-title {
  font-weight: 600;
  font-size: 35px;
  margin-bottom: 20px;
}

.page-content {
  padding: 30px 30px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #e1e3e7;

  &.form {
    padding: 30px 40px;

    .form-group-title {
      margin-top: 0;
      margin-bottom: 8px;
      font-size: 22px;
    }

    .subtitle {
      font-weight: 600;
      font-size: 18px;
      margin-top: 1rem;
    }
  }
}

.item-menu {
  height: 40px;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .p-button-icon {
    font-size: 20px;
  }
}

.table-filters {
  &.collapsed {
    visibility: collapse;
  }
}

.table-headers {
  .table-controls {
    margin: -2px 0px;

    button {
      height: 40px;
      width: 40px;
    }
  }
}

.p-datatable {
  .entity-link {
    display: flex;
    align-items: center;

    .entity-name {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.page-card {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #e1e3e7;
  margin-bottom: 40px;

  .header {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    border-bottom: 1px solid #e1e3e7;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #495057;

    .number {
      display: flex;
      align-self: flex-start;
      flex-shrink: 0;
      width: 24px;
    }
  }

  .body {
    padding: 40px;

    .subtitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #495057;
    }

    .separator {
      border-bottom: 1px solid #e1e3e7;
      margin: 30px 40px 30px;
    }

  }

  &.questionnaire {
    .body {
      padding: 30px 60px;

      .separator {
        margin-left: -60px;
        margin-right: -60px;
      }

      .inline-hint {
        color: #6e757c;
        margin-left: 20px;
      }
    }

    .grid {
      margin: -1rem;

      & > [class*="col-"] {
        padding: 1rem;
      }
    }
  }
}

.page-form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.p-component-overlay {
  background-color: transparent;
}

.separator-with-text {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
  line-height: 0.1em;
  font-size: 24px;
  margin-bottom: 40px;

  span {
    color: #b2b2b2;
    background-color: #f0f3f7;
    padding: 0 10px;
  }
}
